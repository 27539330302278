import { Box,OrbitControls } from '@react-three/drei'
import { Canvas,useLoader } from '@react-three/fiber'
import { Controllers, useHitTest, ARButton, XR } from '@react-three/xr'
import React, { ComponentProps } from 'react'
import { type Mesh } from 'three'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'


export default class Dashboard extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          message: 'Hello, World!'
      };
  }
 


render() {
  return (
    <>
   
    </>
  )
}

}